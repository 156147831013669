// Declare our namspace
window.MBlazor = window.MBlazor || {};
var MBlazor = window.MBlazor;

/**
 * Set focus on specified element
 * @param {Element} Element
 */
MBlazor.SetElemFocus = function (el) {
    el && el.focus();
};

/**
 * Set focus on specified element by selector
 * @param {String} selector Element selector
 */
MBlazor.SetFocus = function (selector) {
    if (selector) {
        var el = document.querySelector(selector);
        MBlazor.SetElemFocus(el);
    }
};

/**
 * Update property of the specific element
 * @param {Element} el Element 
 * @param {String} property Property key to set value on
 * @param {String} value Property value
 */
MBlazor.SetElemProperty = function (el, property, value) {
    if (el && property) {
        el[property] = value;
    }
};

/** 
 *  Get property
*/
MBlazor.GetElemProperty = function (el, property) {
    if (el && property) {
        return el[property];
    }
};

/**
 * Clear focus on specified element
 * @param {Element} el Element
 */
MBlazor.ClearElemFocus = function (el) {
    el && el.blur();
};

/**
 * Clear focus on specified element by selector
 * @param {String} selector Element Selector
 */
MBlazor.ClearFocus = function (selector) {
    if (selector) {
        var el = document.querySelector(selector);
        MBlazor.ClearElemFocus(el);
    }
};

/**
 * Clear all user selection ranges
 */
MBlazor.ClearSelectionRanges = function () {
    var selection = window && window.getSelection();
    selection && selection.removeAllRanges();
};

/**
 * Get bounding rectangle of an element.
 * @param {string | Element} target Selector string or Element itself
 */
MBlazor.GetBoundingClientRect = function (target) {
    var el = target instanceof Element ? target : document.querySelector(target);
    return el && el.getBoundingClientRect();
};

/**
 * Scroll the specified element into view
 * @param {Element} Element
 * @param {boolean} alignToTop Whether to align to the top.
 */
MBlazor.ScrollElemInView = function (el, alignToTop) {
    el && el.scrollIntoView(alignToTop);
};

/**
 * Scroll element specified by selector into view.
 * @param {String} selector Element selector
 * @param {boolean} alignToTop Whether to align to the top.
 */
MBlazor.ScrollIntoView = function (selector, alignToTop) {
    if (selector) {
        var el = document.querySelector(selector);
        MBlazor.ScrollElemInView(el, alignToTop);
    }
};

/**
 * Scroll the specified element
 * @param {Element} Element
 */
MBlazor.ScrollElemBy = function (el, top, left, behavior) {
    el && el.scrollBy({
        top: top,
        left: left,
        behavior: behavior,
    });
};

/**
 * Scroll the specified element
 * @param {Element} Element
 */
MBlazor.ScrollIntoViewOptions = function (el, behavior, block, inline) {
    el && el.scrollIntoView({ behavior: behavior, block: block, inline: inline });
};
