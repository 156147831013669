import './index.scss';
import '@webcomponents/custom-elements';
import '@cds/core/icon/register.js';
import "@cds/core/accordion/register.js";
import '@cds/core/button/register.js';
import '@cds/core/alert/register.js';
import '@cds/core/badge/register.js';
import '@cds/core/checkbox/register.js';
import '@cds/core/forms/register.js';
import '@cds/core/datalist/register.js';
import '@cds/core/input/register.js';
import '@cds/core/modal/register.js';
import '@cds/core/password/register.js';
import '@cds/core/radio/register.js';
import '@cds/core/range/register.js';
import '@cds/core/select/register.js';
import '@cds/core/tag/register.js';
import '@cds/core/textarea/register.js';
import '@cds/core/toggle/register.js';
import 'trianglify/dist/trianglify.bundle.js';
import './js/col-resizer.js';
import './js/common.js';
import './js/input.js';
import Split from 'split.js'

import trianglify from 'trianglify';

import { ClarityIcons } from '@cds/core/icon';

import { loadChartIconSet, loadCommerceIconSet, loadCoreIconSet, loadEssentialIconSet, loadMediaIconSet, loadMiniIconSet, loadSocialIconSet, loadTechnologyIconSet, loadTextEditIconSet, loadTravelIconSet } from '@cds/core/icon';

loadChartIconSet();
loadCommerceIconSet();
loadCoreIconSet();
loadEssentialIconSet();
loadMediaIconSet();
loadMiniIconSet();
loadSocialIconSet();
loadTechnologyIconSet();
loadTextEditIconSet();
loadTravelIconSet();

window.setLoginBackground = function () {
    var pattern = {};
    var login = document.getElementsByClassName('login-wrapper');

    if (!("loginBackground" in sessionStorage)) {
        var options = { height: 2056, width: 1440 };
        var patternGenerate = trianglify(options);
        sessionStorage.setItem('loginBackground', patternGenerate.toSVG().outerHTML);
        pattern = patternGenerate.toSVG().outerHTML;
    } else {
        pattern = sessionStorage.getItem('loginBackground');
    }
    if (login != undefined && login.length > 0) {
        login[0].style.backgroundImage = "url('data:image/svg+xml," + pattern + "')";
    }
}

var splitMainView;

window.setFooterSplitView = function () {
    var splitMainViewSizes = [50, 50];

    if (localStorage.getItem("mainViewSplitSizes") !== null) {
        splitMainViewSizes = JSON.parse(localStorage.getItem('mainViewSplitSizes'));
    } 

    splitMainView = Split(['.content-area', '.content-footer'], {
        direction: 'vertical',
        gutterSize: 10,
        cursor: 'row-resize',
        sizes: splitMainViewSizes,
        onDragEnd: function (sizes) {
            localStorage.setItem('mainViewSplitSizes', JSON.stringify(sizes))
        },
    });
}

window.clearFooterSplitView = function () {
    splitMainView.destroy();
}
