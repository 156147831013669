// Declare our namspace
window.MBlazor = window.MBlazor || {};
var MBlazor = window.MBlazor;

/**
 * Converts an existing table to resizeable table
 * @param {Element} tableEl Table element
 * @param {String} colSelector Table column element selector
 * @param {String} resizerSelector Element selector for resizer control
 */
MBlazor.ResizableTable = function (tableEl, colSelector, resizerSelector) {
    if (!tableEl) return;
    var tbodies = tableEl.querySelectorAll('.datagrid-table');

    var cols = tableEl.querySelectorAll(colSelector);
    for (var i = 0; i < cols.length; i++) {
        var resizer = cols[i].querySelector(resizerSelector);
        setListeners(resizer);
    }

    var theadrows = tableEl.querySelectorAll('.datagrid-header .datagrid-row-scrollable');
    if (theadrows.length > 1) {
        const resizeObserver = new ResizeObserver(entries => {
            var filterCols = entries[0].target.parentElement.children[1].querySelectorAll('.datagrid-column');
            for (var i = 0; i < filterCols.length; i++) {
                filterCols[i].style.top = entries[0].target.clientHeight + 'px';
            }
        });
        resizeObserver.observe(theadrows[0]);
    }

    var pageX, curCol, nxtCol, curColWidth, nxtColWidth, curResizer;

    function setListeners(resizer) {
        resizer.addEventListener('mousedown', function (e) {
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);

            curResizer = resizer;
            curCol = e.target.parentElement;
            nxtCol = curCol.nextElementSibling;
            pageX = e.pageX;
                        
            var tbodyHeight = getBodyHeight();
            resizer.style.height = (resizer.offsetHeight + tbodyHeight) + 'px';

            var padding = paddingDiff(curCol);

            curColWidth = curCol.offsetWidth - padding;
            if (nxtCol)
                nxtColWidth = nxtCol.offsetWidth - padding;
        });
    }

    function getBodyHeight() {
        var height = 0;
        for (var i = 0; i < tbodies.length; i++) {
            height += tbodies[i].offsetHeight;
        }
        return height;
    }

    function mouseMoveHandler(e) {
        if (curCol) {
            var diffX = e.pageX - pageX;

            if (nxtCol)
                nxtCol.style.width = (nxtColWidth - (diffX)) + 'px';

            curCol.style.width = (curColWidth + diffX) + 'px';
        }
    }

    function mouseUpHandler() {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);

        curResizer.style.removeProperty('height');

        curResizer = undefined;
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined
    }

    function paddingDiff(col) {
        if (getStyleVal(col, 'box-sizing') == 'border-box') {
            return 0;
        }

        var padLeft = getStyleVal(col, 'padding-left');
        var padRight = getStyleVal(col, 'padding-right');
        return (parseInt(padLeft) + parseInt(padRight));

    }

    function getStyleVal(elm, css) {
        return (window.getComputedStyle(elm, null).getPropertyValue(css))
    }
};